import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars, faBriefcase, faEnvelope, faFont,
  faImages, faLink, faMapMarkerAlt, faPhoneAlt, faRedoAlt, faTimes, faGlobe, faVoicemail, faArrowRight, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'
import '../components/styles.css';
import '../components/home.css';

import isassinatura from '../img/investsmart_assinatura.png';
import investsmart from '../img/img-thumbs/investsmart.png';
import isassessoria from '../img/img-thumbs/isassessoria.png';
import assessoriaIS from '../img/img-thumbs/assessoriaIS.png';
import besmart from '../img/img-thumbs/besmart.png';
import imoveis from '../img/img-thumbs/imoveis-2.png';
import capa from '../img/img-thumbs/img_capa_vdois.png';
import logois from '../img/logos/logo-investsmart-pr-br.png';
import logobe from '../img/logos/logo-besmart-pb.png';

import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Container from 'react-bootstrap/Container';
import { Button, CloseButton } from 'react-bootstrap';
import IsAssessoria from './isassessoria';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Alerta from '../components/Alerta';

function Home() {


  return (
    <>

      <div class="is-capa pd-top-120   bg-roxo text-white pd-bottom-120 pr-50 pl-50">
        <div class="container">
          <div class="row">

            <div class="col-lg-6">
              <div class="d-inline pr-20 ">
                <img src={logois} class="logo-is" width={207} />
              </div>
              <div class="d-inline pr-20 ">
                <img src={logobe} width={174} class="logo-be" />


              </div>
              <div class="section-title mt-40">

                <h1 class="title">Crie sua assinatura de email</h1>
                <p class="content">Obtenha uma linda assinatura de email que vai causar bela impressão. Selecione o email que possui e gere facilmente sua assinatura em 2 minutos. </p>

              </div>
            </div>
            <div class="col-lg-4 mb-4 mb-lg-0">
              <div class="thumb-inner pl-50">
                <img src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/banner_assinatura_is-removebg-preview.png" className="" />
              </div>
            </div>
          </div>
        </div>
      </div>



      <div id="cta" class="bg-claro">
        <div class="container  pt-80 pb-12 is-2 py-md-80 ">
          <ol class="horizontal-order is-1 is-md-12">
            <li data-count="1">
              <h2 class="title-box">Selecione a assinatura de acordo com o seu perfil</h2>
              <p class="mt-4">Para cada email uma assinatura específica.</p>
            </li>
            <li data-count="2">
              <h2 class="title-box">Preencha os seus dados</h2>
              <p class="mt-4">Preencha suas informações para
                que possamos gerar uma assinatura
                de email para você.</p>
            </li>
            <li data-count="3">
              <h2 class="title-box">Configure sua assinatura nova</h2>
              <p class="mt-4">Copie e cole sua assinatura no seu Outlook.</p>
            </li>

          </ol>

        </div>
      </div>



      <div class="is-area bg-claro pr-50 pl-50 pb-150 ">
        <div class="container">
          <div class="row text-center">
            <div class="col-lg-12  pb-80">
              <h2 class=" title "> Selecione a assinatura de acordo com o seu perfil</h2>
              <p class="pt-20"> Escolha abaixo o seu perfil e selecione o seu email correspondente.</p>
            </div>
          </div>

          <Tabs
            defaultActiveKey=""
            id="uncontrolled-tab-example"
            className="mb-3" >
            <Tab eventKey="assessor-b2b" title="Sou Assessor - Email auditado @isassessoria.com.br">
              <div class="row">
                <div class="col-lg-12 text-center pt-40">
                  <h2> Olá, assessor!  Informamos que é obrigatória a criação das 2 assinaturas de e-mail abaixo. </h2>
                </div>
              </div>
              <div class="row pd-bottom-120 pd-top-120">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="thumb-inner">
                    <img src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/thumb_assinatura_is.png" className="model-email" />
                    {/* <img src={investsmart} class="model-email" /> */}
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="section-title mb-0">
                    <h6 class="sub-title mr-20">Não Auditado</h6>
                    <h6 class="sub-title mr-20 bg-verde">E-mail Padrão </h6>
                    <h2 class="title">Email @investsmart.com.br</h2>
                    <p class="content">Se você tem o email @investsmart.com.br seleciona esse modelo primeiro, esse email é fundamental que esteja com a sua assinatura correta.  </p>
                    <p class="content">  <i><FontAwesomeIcon icon={faExclamationTriangle} /></i>  Essa assinatura é atrelada ao e-mail Institucional da nossa empresa e deve ser utilizada para a comunicação interna com todas as áreas. </p>
                    <Link to="/InvestSmart">
                      <Button className='btn btn-border-base  mt-20'> Gerar Assinatura</Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="row pd-top-120 ">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="thumb-inner">
                    <img src={isassessoria} class="model-email" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="section-title mb-0">
                    <h6 class="sub-title text-black mr-20 bg-verde"> B2C </h6>
                    <h6 class="sub-title text-black mr-20">Matriz </h6>

                    <h6 class="sub-title text-black mr-20">Growth </h6>

                    <h6 class="sub-title text-black">At Home </h6>

                    <h2 class="title">Email @isassessoria.com.br</h2>
                    <p class="content">Se você tem o email @isassessoria.com.br selecione esse modelo para gerar a assinatura.   </p>
                    <p class="content">  <i><FontAwesomeIcon icon={faExclamationTriangle} /></i>   Esse e-mail é monitorado pela corretora, por isso o assessor deve ter atenção ao utiliza-lo no seu dia-a-dia. Essa assinatura, possui Disclaimer. </p>
                    <Link to="/isassessoria">
                      <Button className='btn btn-border-base  mt-20'> Gerar Assinatura         </Button>
                    </Link>
                  </div>
                </div>
              </div>

            </Tab>
            <Tab eventKey="assessor" title="Sou Assessor - Email auditado @assessoriainvestsmart.com.br">
              <div class="row">
                <div class="col-lg-12 text-center pt-40">
                  <h2> Olá, assessor! Se você trabalha em alguma de nossas filiais, você está no local certo. Você precisa criar as 2 assinaturas abaixo correspondente ao seus emails. </h2>
                </div>
              </div>

              <div class="row pd-bottom-120 pd-top-120">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="thumb-inner">
                    <img src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/thumb_assinatura_is.png" className="model-email" />
                    {/* <img src={investsmart} class="model-email" /> */}
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="section-title mb-0">

                    <h6 class="sub-title mr-20">Não Auditado</h6>
                    <h6 class="sub-title mr-20 bg-verde">E-mail Padrão </h6>
                    <h2 class="title">Tenho Email @investsmart.com.br</h2>
                    <p class="content">Se você tem o email @investsmart.com.br seleciona esse modelo primeiro, esse email é fundamental que esteja com a sua assinatura correta.  </p>
                    <p class="content">  <i><FontAwesomeIcon icon={faExclamationTriangle} /></i>  Essa assinatura é atrelada ao e-mail Institucional da nossa empresa e deve ser utilizada para a comunicação interna com todas as áreas. </p>
                    <Link to="/InvestSmart">
                      <Button className='btn btn-border-base  mt-20'> Gerar Assinatura</Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="row pd-top-120">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="thumb-inner">
                    <img src={assessoriaIS} class="model-email" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="section-title mb-0">
                    <h6 class="sub-title text-black mr-20 "> B2C </h6>
                    <h6 class="sub-title bg-verde  text-black">Novo! </h6>
                    <h2 class="title">Tenho o Email @assessoriainvestsmart.com.br</h2>
                    <p class="content">Essa assinatura, é vinculada ao email utilizado para comunicação da própria XP e envio de ordens aos clientes.   </p>
                    <p class="content">  <i><FontAwesomeIcon icon={faExclamationTriangle} /></i>  Esse e-mail é monitorado pela corretora, por isso o assessor deve ter atenção ao utiliza-lo no seu dia-a-dia. Essa assinatura, possui Disclaimer.  </p>
                    <Link to="/assessoriais">
                      <Button className='btn btn-border-base  mt-20'>Gerar Assinatura</Button>
                    </Link>
                  </div>
                </div>
              </div>

            </Tab>
            <Tab eventKey="besmart" title="Trabalho na Be.Smart">
              <div class="row pd-top-120">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="thumb-inner">
                    <img src={besmart} class="model-email" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="section-title mb-0">
                    <h6 class="sub-title text-black mr-20">Crédito </h6>
                    <h6 class="sub-title text-black mr-20">Seguros </h6>
                    <h6 class="sub-title text-black mr-20">Câmbio </h6>
                    <h6 class="sub-title text-black mr-20">Operacional </h6>
                    <h2 class="title">Email @besmart.com.br</h2>
                    <p class="content">Se você tem o email @besmart.com.br você deve selecionar esse modelo para gerar a sua assinatura. Essa assinatura é exclusiva para os comerciais da be.smart e seu time operacional.  </p>

                    <Alerta />
                    <Link to="/besmart">
                      <Button className='btn btn-border-base  mt-20'>Gerar Assinatura</Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div class="row pd-top-120" >
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="thumb-inner">
                    <img src={imoveis} class="model-email" />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="section-title mb-0">
                    <h6 class="sub-title text-black mr-20">Corretores </h6>
                    <h6 class="sub-title text-black">Operacional </h6>
                    <h2 class="title">Email @besmartimoveis.com.br</h2>
                    <p class="content">Se você trabaçha na be.smart imóveis, você deve selecionar essa assinatura.   </p>
                    <Alerta />


                    <Link to="/imoveis">
                      <Button className='btn btn-border-base  mt-20'>Gerar Assinatura</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="backoffice" title="BackOffice InvestSmart" >
              <div class="row pd-bottom-120 pd-top-120">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="thumb-inner">
                    <img src="https://cdn-hubsmart.s3.amazonaws.com/redes-sociais/thumb_assinatura_is.png" className="model-email" />
                    {/* <img src={investsmart} class="model-email" /> */}
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="section-title mb-0">
                    <h6 class="sub-title">BackOffice</h6>
                    <h2 class="title">Email @investsmart.com.br</h2>
                    <p class="content">Se você tem o email @investsmart.com.br seleciona esse modelo para gerar a sua assinatura. Essa assinatura, atende basicamente o time de BackOffice e não tem o Disclaimer.  </p>
                    <Link to="/InvestSmart">
                      <Button className='btn btn-border-base  mt-20'> Gerar Assinatura</Button>
                    </Link>
                  </div>
                </div>
              </div>      </Tab>
          </Tabs>



        </div>
      </div>


    </>

  )
}

export default Home;
